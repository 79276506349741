import React, {useState} from 'react';
import {
    Badge,
    Box, Dialog,
    Grid, Modal,
    Typography,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {frFR} from '@mui/x-data-grid/locales';
import {fr} from "date-fns/locale";
import {eachDayOfInterval, format, isValid} from "date-fns";
import DetailHeureModal from "./heures/DetailHeureModal.js";
import Constants from "../../../utils/constants.js";
import {connect, useDispatch} from "react-redux";
import {quartixActions} from "../../../modules/quartix/quartix.actions.js";
import {heuresActions} from "../../../modules/heures/heures.actions.js";


function RHSuiviHeuresTable({heures, quartixData, dateRange, loading}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const dispatch = useDispatch();

    const handleCellClick = (params) => {
        const data = params.value;
        const rowData = params.row;
        console.log(data);
        if (data && rowData) {
            const operateur = rowData.name.split(" (")[0];
            setSelectedData({
                ...data,
                operateur: operateur
            });
        }

        const quartixIdDebut = data?.details?.quartixIdDebut;
        const quartixIdFin = data?.details?.quartixIdFin;

        if (quartixIdDebut && !quartixData[quartixIdDebut]?.[data?.date]) {
            dispatch(quartixActions.fetchEngineTimes(data?.date, quartixIdDebut));
        }
        if (quartixIdFin && !quartixData[quartixIdFin]?.[data?.date]) {
            dispatch(quartixActions.fetchEngineTimes(data?.date, quartixIdFin));
        }

        setModalOpen(true);
    };

    // Validation de l'intervalle des dates
    if (
        !dateRange.startDate ||
        !dateRange.endDate ||
        !isValid(new Date(dateRange.startDate)) ||
        !isValid(new Date(dateRange.endDate)) ||
        new Date(dateRange.startDate) > new Date(dateRange.endDate)
    ) {
        console.error("Intervalle invalide : ", dateRange);
        return <div>Erreur : Intervalle invalide.</div>;
    }

    // Génération des colonnes pour chaque jour du mois
    const columns = [
        {
            field: "name",
            headerName: "Opérateur",
            width: 200,
            renderCell: (params) => (
                <div style={{padding: "10px"}}>
                    {params.value}
                </div>
            ),
            headerClassName: "operator-header",
        },
        ...eachDayOfInterval({
            start: new Date(dateRange.startDate),
            end: new Date(dateRange.endDate),
        }).map((date) => ({
            field: format(date, "yyyy-MM-dd"),
            headerName: `${format(date, "EEEE", {locale: fr})}\n${format(date, "d")}`,
            width: 60,
            renderCell: renderCellStyle,
        })),
    ];

    // Transformation des données pour les `rows`
    const rows = heures?.map((item, index) => {
        const totalMinutes = item.totalMinutes || 0; // Default to 0 if not present
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return {
            id: index,
            name: ` ${item.operateur} (${hours}h${minutes > 0 ? minutes + "m" : ""})`,
            ...item.suivi.reduce((acc, day) => {
                acc[day.date] = day;
                return acc;
            }, {}),
        };
    });

    // Rendu personnalisé pour les cellules en fonction du statut
    function renderCellStyle(params) {
        const data = params.value; // `params.value` contient tout l'objet d'un jour
        if (!data) return null;

        const {status, details} = data;
        const workMinutes = details?.workMinutes || 0;
        const validatedAt = details?.validatedAt

        const cellSize = {width: '60px', height: '60px', position: 'relative'}

        const styleMap = {
            vert: {backgroundColor: Constants.COLOR_THEME_GREEN, color: "black", ...cellSize},
            orange: {backgroundColor: Constants.COLOR_THEME_ORANGE, color: "black", ...cellSize},
            rouge: {backgroundColor: Constants.COLOR_THEME_RED, color: "white", ...cellSize},
            gris: {backgroundColor: "grey", color: "black", ...cellSize},
        };

        return (
            <div
                style={{
                    ...styleMap[status],
                    textAlign: "center",
                }}
                onClick={() => handleCellClick(params)}
            >
                {status === "vert" &&
                    <>
                        {Math.floor(workMinutes / 60)}h{workMinutes % 60 > 0 ? workMinutes % 60 + "m" : ""}
                        {!validatedAt && (
                            <div className={"heure-to-validate"}></div>
                        )}
                    </>

                }
                {status === "orange" &&
                    <>
                        {!validatedAt && (
                            <div className={"heure-to-validate"}></div>
                        )}
                    </>
                }
                {status === "rouge" && ""}
                {status === "gris" && ""}

            </div>
        );
    }

    const updateWorkMinutesLocally = (id, heureDebut, heureFin, tempsPauseMinutes) => {
        const start = new Date(heureDebut);
        const end = new Date(heureFin);

        const workMinutes = (end - start) / (1000 * 60) - tempsPauseMinutes; // Différence en minutes

        // Mise à jour de la données qui est passée à la modal
        setSelectedData((prevData) => ({
            ...prevData,
            details: {
                ...prevData.details,
                debut: heureDebut,
                fin: heureFin,
                tempsPauseMinutes: tempsPauseMinutes,
                workMinutes,
            },
        }));

        // Mise à jour du tableau
        heures.forEach((row) => {
            row.suivi.forEach((day) => {
                if (day.id === id) {
                    day.details.workMinutes = workMinutes;
                    day.details.debut.date = heureDebut;
                    day.details.fin.date = heureFin;
                    day.details.tempsPauseMinutes = tempsPauseMinutes
                }
            });
        });
    };

    const onFdtUpdate = (values) => {
        if (values.id) {
            dispatch(heuresActions.updateFeuilleDeTemps(values.id, values))
                .then(data => {
                    if (data.heureDebut && data.heureFin && data.tempsPauseMinutes) {
                        updateWorkMinutesLocally(values.id, data.heureDebut, data.heureFin, data.tempsPauseMinutes);
                    }
                })
                .catch(error => {
                    console.error("Failed to update feuille de temps:", error);
                });
        }
    }

    const onFdtValidate = (fdtId) => {
        if (fdtId) {
            const values = {
                id: fdtId,
                validatedAt: new Date().toISOString()
            }
            dispatch(heuresActions.updateFeuilleDeTemps(values.id, values)).then(() => {
                setModalOpen(false)
            })
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Suivi des heures</Typography>
            </Grid>
            <Grid item xs={12}>
                <div style={{height: "100%", width: "100%"}}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableSelectionOnClick
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSorting
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                                whiteSpace: "pre-wrap", // Autorise les sauts de ligne
                                textAlign: "center",   // Centre le texte
                                lineHeight: "1.2",    // Ajuste la hauteur des lignes
                            },
                            "& .MuiDataGrid-cell": {
                                padding: 0,
                                border: '1px solid lightgrey',
                                cursor: 'pointer'
                            }
                        }}
                    />
                </div>
            </Grid>

            {/* Modal pour afficher les détails */}
            <DetailHeureModal
                open={modalOpen} handleClose={() => setModalOpen(false)}
                data={selectedData} quartixData={quartixData}
                loading={loading} onFdtUpdate={onFdtUpdate}
                onFdtValidate={onFdtValidate}/>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    quartixData: state.quartix.data,
    loading: state.quartix.loading,
    error: state.quartix.error,
});

export default connect(mapStateToProps)(RHSuiviHeuresTable);
