import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import withWidth from "@mui/material/Hidden/withWidth";
import {NavLink, useNavigate} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import {Grid, Menu, MenuItem} from "@mui/material";
import './Header.css';
import {Role} from "../../../utils/roles";
import Constants from "../../../utils/constants";
import {authActions} from "../../../modules/authentication/auth.actions";
import {useDispatch} from "react-redux";

function Header({...props}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const logout = () => {
        dispatch(authActions.logout()).then(() => {
            navigate(Constants.APP_ROUTE_LOGIN)
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function ModuleAdministration() {
        return (
            <Menu
                id="menu-admin"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {(props.user.roles.includes(Role.ROLE_ADMIN) || props.user.roles.includes(Role.ROLE_EXPLOITANT)) &&
                    <MenuItem onClick={handleClose}>
                        <NavLink to={Constants.APP_ROUTE_ADMIN_USERS} className={"menuNavLink"}>
                            <Button size={"small"} className="button-nav">
                                Utilisateurs
                            </Button>
                        </NavLink>
                    </MenuItem>
                }
                {props.user.roles.includes(Role.ROLE_ADMIN) &&
                    <MenuItem onClick={handleClose}>
                        <NavLink to={Constants.APP_ROUTE_ADMIN_PROBLEMS} className={"menuNavLink"}>
                            <Button size={"small"} className="button-nav">
                                Problèmes
                            </Button>
                        </NavLink>
                    </MenuItem>
                }
                {props.user.roles.includes(Role.ROLE_ADMIN) &&
                    <MenuItem onClick={handleClose}>
                        <NavLink to={Constants.APP_ROUTE_ADMIN_FORMS} className={"menuNavLink"}>
                            <Button size={"small"} className="button-nav">
                                Formulaires
                            </Button>
                        </NavLink>
                    </MenuItem>
                }
                {props.user.roles.includes(Role.ROLE_ADMIN) &&
                    <MenuItem onClick={handleClose}>
                        <NavLink to={Constants.APP_ROUTE_ADMIN_LAVEUSES} className={"menuNavLink"}>
                            <Button size={"small"} className="button-nav">
                                Laveuses
                            </Button>
                        </NavLink>
                    </MenuItem>
                }


            </Menu>);

    }

    return (
        <AppBar color={"default"} position={"fixed"} elevation={0} className="appbar">
            <Toolbar variant={"dense"} disableGutters={true}>
                <div className={"brand"} onClick={() => navigate(Constants.APP_ROUTE_HOME)}></div>
                <Grid container>
                    <Grid item container className="menu-container" spacing={1} xs={10} alignItems={"center"}
                          justify="flex-start">

                        {(props.user.roles.includes(Role.ROLE_ADMIN) || props.user.roles.includes(Role.ROLE_EXPLOITANT)) &&
                            <Grid item>
                                <Button aria-controls="menu-admin" aria-haspopup="true" className="button-nav"
                                        size="large"
                                        onClick={handleClick}>
                                    Administration
                                </Button>
                                <ModuleAdministration/>
                            </Grid>
                        }

                        {(props.user.roles.includes(Role.ROLE_ADMIN) || props.user.roles.includes(Role.ROLE_EXPLOITANT)
                                || props.user.roles.includes(Role.ROLE_CLIENT)) &&
                            <Grid item>
                                <NavLink to={Constants.APP_ROUTE_PATRIMOINE} className={"menuNavLink"}>
                                    <Button size={"small"} className="button-nav">
                                        Patrimoine
                                    </Button>
                                </NavLink>
                            </Grid>
                        }
                        {props.user.roles.includes(Role.ROLE_EXPLOITANT) &&
                            <Grid item>
                                <NavLink to={Constants.APP_ROUTE_ADMIN_CAMPAIGNS} className={"menuNavLink"}>
                                    <Button size={"small"} className="button-nav">
                                        Tournées de lavage
                                    </Button>
                                </NavLink>
                            </Grid>
                        }
                    </Grid>
                    <Grid item container xs={2} justify="flex-end">
                        <Button variant="contained" color="primary" className="logout-button"
                                startIcon={<LogoutIcon/>} onClick={logout}>
                            Se déconnecter
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default withWidth()(Header);
