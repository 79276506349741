import React, {useState} from 'react';
import './PhotoCarousel.css';
import Carousel from "react-material-ui-carousel";
import {Box, Modal, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import Button from "@mui/material/Button";

function PhotoCarousel({photoArray, assetsUrl, headerStyle, label, clickable = true, ...props}) {
    const [openModal, setOpenModal] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [rotation, setRotation] = useState(0); // Gère la rotation de la photo

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickPhoto = (photo) => {
        if (clickable) {
            setSelectedPhoto(photo);
            setRotation(0); // Réinitialise la rotation à 0
            setOpenModal(true);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedPhoto(null);
    };

    const handleRotatePhoto = () => {
        setRotation((prev) => (prev + 90) % 360); // Incrémente de 90° (rotation circulaire)
    };

    const handleDownloadPhoto = () => {
        const link = document.createElement('a');
        link.href = assetsUrl + selectedPhoto;
        link.download = selectedPhoto;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Paper className={"carousel-wrapper"}>
            <Typography variant={"h6"} className={"carousel-label " + headerStyle}>{label}</Typography>
            {(!photoArray || photoArray?.length === 0) &&
                <Typography variant={"body1"} sx={{textAlign: 'center', padding: '1rem !important'}}>
                    Aucune photo
                </Typography>
            }
            {photoArray?.length > 0 &&
                <Carousel
                    className={"carousel-container"}
                    animation={"slide"}
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'var(--thm-black)',
                            opacity: 1
                        }
                    }}
                    indicatorIconButtonProps={{
                        style: {}
                    }}
                >
                    {
                        photoArray?.map((photo, i) => (
                            <img
                                key={i}
                                src={assetsUrl + photo}
                                alt="Intervention photo"
                                className={"intervention_photo"}
                                onClick={() => handleClickPhoto(photo)}
                                style={{cursor: 'pointer'}}
                            />
                        ))
                    }
                </Carousel>
            }

            {/* Modal pour afficher la photo en grand */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="large-photo-modal"
                aria-describedby="large-photo-modal-description"
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        padding: isMobile ? '1rem' : '2rem 1rem',
                        borderRadius: '8px',
                        boxShadow: 24,
                        outline: 'none',
                        maxWidth: isMobile ? '95%' : '90%',
                        maxHeight: isMobile ? '95vh' : '90vh',
                        width: isMobile ? '95%' : 'auto',
                        height: isMobile ? 'auto' : '90%',
                        overflow: 'hidden',
                    }}
                >
                    {/* Conteneur de l'image */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={assetsUrl + selectedPhoto}
                            alt="Large intervention photo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                borderRadius: '8px',
                                transform: `rotate(${rotation}deg)`,
                                transition: 'transform 0.3s ease',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                    {/* Boutons d'action */}
                    <Box sx={{marginTop: '1rem', display: 'flex', gap: '1rem', flexShrink: 0}}>
                        <Button
                            variant="outlined"
                            onClick={handleRotatePhoto}
                            style={{padding: '0.5rem 1rem', backgroundColor: "#eee"}}
                        >
                            Tourner
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleDownloadPhoto}
                            style={{padding: '0.5rem 1rem', backgroundColor: "#eee"}}
                        >
                            Télécharger
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Paper>
    );
}

export default PhotoCarousel;
